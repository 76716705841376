import React from 'react';
import { fadeIn } from '../Variants';
import { motion } from 'framer-motion';
import Adobe from '../assets/Adobe.jpg';
import Aws from '../assets/Aws.png';
import Figma from '../assets/Figma.jpg';
import Xd from '../assets/Xd.png';
import Chatgpt from '../assets/Chatgpt.jpg';
import Illustrator from '../assets/Illustrator.jpg';
import Midjourney from '../assets/Midjourney.jpeg';
import Photoshop from '../assets/Photoshop.jpg';
import Blender from '../assets/Blender.png'
import { img } from 'framer-motion/client';
// const ImageGrid = () => {
//   return (
//     <div id="work" style={{ padding: '20px',
//          maxHeight: '100vh', width: '', 
//     textAlign: 'center', color: '#ff0e6c', fontSize: '30px', fontWeight: '700'  }}>
//     <motion.div
//      variants={fadeIn("up", 0.1)}
//      initial="hidden"
//      whileInView={"show"}
//      viewport={{ once: false, amount: 0.7 }}
//      >
//         <h1>Technologies we work with</h1>
//        <div style={styles.imageGrid}>
//         {images.map((src, index) => (
//           <div key={index} style={{ ...styles.imageContainer, ...styles.offset(index) }}>
//             <img src={src} alt={`Image ${index + 1}`} style={styles.image} />
//           </div>
//         ))}
//        </div>
//       </motion.div>
//     </div>
//   );
// };

// const styles = {
//   imageGrid: {
//     display: 'flex',
//     flexWrap: 'wrap',
//     width: '800px',
//     gap: '50px',
//     margin: '150px auto 0 auto',
//   },
//   imageContainer: {
//     width: '50px',
//     height: '100px',
//     overflow: 'hidden',
//     flex: '1 0 15%',
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     backgroundColor: 'white',
//     borderRadius: '30%',
//     transition: 'all 0.3s ease',
//   },
//   image: {
//     width: '70%',
//     height: '70%',
//     objectFit: 'cover',
//   },
//   // Apply offset for specific columns
//   offset: (index) => {
//     if (index === 1 || index === 3 || index === 6 || index === 8) {
//       return { marginTop: '-60px' }; // Slightly higher for 2nd and 4th columns
//     }
//     return {};
//   },
// };

// export default ImageGrid;
const TechSlider = () => {
  const Tech = [
    { name : "Adobe" , img : "../assets/Adobe.jpg"},
    { name : "AWS", img: {Aws}},
    { name : "Chatgpt", img : {Chatgpt}},
    { name : "XD", img : {Xd}},
    { name : "Figma", img : {Figma}},
    { name : "Blender", img : {Blender}},
    { name : "Illustrator", img : {Illustrator}},
    { name : "Photoshop", img: {Photoshop}},
    { name : "Midjourney", img : {Midjourney}},

  ];
  return(
    <div className='   w-5/6 mx-auto  overflow-hidden   '>
      <h2 className=' text-pink-500 font-semibold text-4xl text-center'>Tech Stack We Work With</h2>
      <div className=' '>
        <div className=' flex   gap-4'>
          
            <div
            className='   pt-40 pb-40 flex   gap-10  animate-loop-scroll  items-center justify-evenly  w-32 h-32'>
              <img src={Adobe}></img>
              <img src={Aws}></img>
              <img src={Figma}></img>
              <img src={Photoshop}></img>
              <img src={Illustrator}></img>
              <img src= {Blender}  ></img>
              <img src= {Midjourney}></img>
              <img src={Xd}></img>
              <img src={Chatgpt}></img>

            </div>

        </div>

      </div>
    </div>
  )
}
export default TechSlider