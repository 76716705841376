import ReactFlow, { ReactFlowProvider, Background, Controls, useReactFlow } from 'reactflow';
import 'reactflow/dist/style.css';
import React, { useEffect, useState } from 'react';
import SparkstartLogo from '../assets/SparkStartLogo02.png';



const edges = [
  { id: '1-2', source: '2', target: '1' },
  { id: '1-3', source: '1', target: '3' },
  { id: '1-4', source: '1', target: '4' },
  { id: '1-5', source: '5', target: '1' },
  { id: '1-6', source: '1', target: '6' },
  { id: '2-2-1', source: '2-1', target: '2' },
  { id: '2-2-2', source: '2-2', target: '2' },
  { id: '2-2-3', source: '2', target: '2-3' },
  { id: '2-2-4', source: '2', target: '2-4' },
  { id: '3-3-1', source: '3-1', target: '3' },
  { id: '3-3-2', source: '3-2', target: '3' },
  { id: '3-3-3', source: '3', target: '3-3' },
  { id: '3-3-4', source: '3', target: '3-4' },
  { id: '4-4-1', source: '4', target: '4-1' },
  { id: '4-4-2', source: '4', target: '4-2' },
  { id: '4-4-3', source: '4', target: '4-3' },
  { id: '4-4-4', source: '4', target: '4-4' },
  { id: '5-5-1', source: '5-1', target: '5' },
  { id: '5-5-2', source: '5-2', target: '5' },
  { id: '5-5-3', source: '5', target: '5-3' },
  { id: '5-5-4', source: '5', target: '5-4' },
  { id: '6-6-1', source: '6-1', target: '6' },
  { id: '6-6-2', source: '6-2', target: '6' },
  { id: '6-6-3', source: '6', target: '6-3' },
  { id: '6-6-4', source: '6', target: '6-4' },
  

];

const nodes = [
  {
    id: '1',
  data: {
    label: (
      <img
        src={SparkstartLogo} // Replace with the actual image path
        alt="Logo"
        style={{ width: '150px', height: '150px', objectFit: 'cover' }} // Adjust styles as needed
      />
    ),
  },
  position: { x: 0, y: 0 },
  type: 'default'
  },
  {
    id: '2',
    data: { label: 'Design & Branding' },
    position: { x: 150, y: -200 },
    style: {
      border: '2px solid rgb(222, 8, 83)',
      backgroundColor: 'rgb(222, 8, 83)',
      color: 'white',
      borderRadius: '8px',
      padding: '10px',
      transition: 'all 0.3s ease, transform 0.3s cubic-bezier(0.23, 1, 0.32, 1)',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
      fontSize: '18px',
    },
    className: 'parent-node',
  },
  
  {
    id: '3',
    data: { label: 'Web & Mobile Development' },
    position: { x: 250, y: 100 },
    style: {
      border: '2px solid rgb(222, 8, 83)',
      backgroundColor: 'rgb(222, 8, 83)',
      color: 'white',
      borderRadius: '8px',
      padding: '10px',
      transition: 'all 0.3s ease, transform 0.3s cubic-bezier(0.23, 1, 0.32, 1)',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
      fontSize: '18px',
    },
    className: 'parent-node',
  },
  {
    id: '4',
    data: { label: 'IoT Development' },
    position: { x: 0, y: 200 },
    style: {
      border: '2px solid rgb(222, 8, 83)',
      backgroundColor: 'rgb(222, 8, 83)',
      color: 'white',
      borderRadius: '8px',
      padding: '10px',
      transition: 'all 0.3s ease, transform 0.3s cubic-bezier(0.23, 1, 0.32, 1)',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
      fontSize: '18px',
    },
    className: 'parent-node',
  },
  {
    id: '5',
    data: { label: 'Social Media Management' },
    position: { x: -150, y: -200 },
    style: {
      border: '2px solid rgb(222, 8, 83)',
      backgroundColor: 'rgb(222, 8, 83)',
      color: 'white',
      borderRadius: '8px',
      padding: '10px',
      transition: 'all 0.3s ease, transform 0.3s cubic-bezier(0.23, 1, 0.32, 1)',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
      fontSize: '18px',
    },
    className: 'parent-node',
  },
  {
    id: '6',
    data: { label: 'Legal & Financial Consulting' },
    position: { x: -250, y: 100 },
    style: {
      border: '2px solid rgb(222, 8, 83)',
      backgroundColor: 'rgb(222, 8, 83)',
      color: 'white',
      borderRadius: '8px',
      padding: '10px',
      transition: 'all 0.3s ease, transform 0.3s cubic-bezier(0.23, 1, 0.32, 1)',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
      fontSize: '18px',
    },
    className: 'parent-node',
  },
  { id: '2-1', data: { label: 'Logo & Identity Design' }, position: { x: 350, y: -300 }, className: 'child-node',
  style: {
    border: '2px',
    backgroundColor: '#000518',
    color: 'white',
    borderRadius: '8px',
    padding: '10px',
    transition: 'all 0.3s ease, transform 0.3s cubic-bezier(0.23, 1, 0.32, 1)',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
    fontSize: '18px',
  }, },
  { id: '2-2', data: { label: 'Brand Kits & Guidelines' }, position: { x: 550, y: -250 }, className: 'child-node', 
  style: {
    border: '2px',
    backgroundColor: '#000518',
    color: 'white',
    borderRadius: '8px',
    padding: '10px',
    transition: 'all 0.3s ease, transform 0.3s cubic-bezier(0.23, 1, 0.32, 1)',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
    fontSize: '18px',
  },},
  { id: '2-3', data: { label: 'Visual Content Creation' }, position: { x: 350, y: -100 }, className: 'child-node',
  style: {
    border: '2px',
    backgroundColor: '#000518',
    color: 'white',
    borderRadius: '8px',
    padding: '10px',
    transition: 'all 0.3s ease, transform 0.3s cubic-bezier(0.23, 1, 0.32, 1)',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
    fontSize: '18px',
  }, },
  { id: '2-4', data: { label: 'Print & Digital Media Design' }, position: { x: 550, y: -150 }, className: 'child-node',
  style: {
    border: '2px',
    backgroundColor: '#000518',
    color: 'white',
    borderRadius: '8px',
    padding: '10px',
    transition: 'all 0.3s ease, transform 0.3s cubic-bezier(0.23, 1, 0.32, 1)',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
    fontSize: '18px',
  }, },

  { id: '3-1', data: { label: 'Website Development' }, position: { x: 500, y: 25 }, className: 'child-node',
  style: {
    border: '2px',
    backgroundColor: '#000518',
    color: 'white',
    borderRadius: '8px',
    padding: '10px',
    transition: 'all 0.3s ease, transform 0.3s cubic-bezier(0.23, 1, 0.32, 1)',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
    fontSize: '18px',
  }, },
  { id: '3-2', data: { label: 'Mobile App Development' }, position: { x: 700, y: -50 }, className: 'child-node',
  style: {
    border: '2px',
    backgroundColor: '#000518',
    color: 'white',
    borderRadius: '8px',
    padding: '10px',
    transition: 'all 0.3s ease, transform 0.3s cubic-bezier(0.23, 1, 0.32, 1)',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
    fontSize: '18px',
  }, },
  { id: '3-3', data: { label: 'API & Backend Integration' }, position: { x: 500, y: 175 }, className: 'child-node',
  style: {
    border: '2px',
    backgroundColor: '#000518',
    color: 'white',
    borderRadius: '8px',
    padding: '10px',
    transition: 'all 0.3s ease, transform 0.3s cubic-bezier(0.23, 1, 0.32, 1)',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
    fontSize: '18px',
  }, },
  { id: '3-4', data: { label: 'UI/UX Design and Prototyping' }, position: { x: 700, y: 250 }, className: 'child-node',
  style: {
    border: '2px',
    backgroundColor: '#000518',
    color: 'white',
    borderRadius: '8px',
    padding: '10px',
    transition: 'all 0.3s ease, transform 0.3s cubic-bezier(0.23, 1, 0.32, 1)',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
    fontSize: '18px',
  }, },

  { id: '4-1', data: { label: 'Custom IoT Solutions' }, position: { x: -100, y: 300 }, className: 'child-node',
  style: {
    border: '2px',
    backgroundColor: '#000518',
    color: 'white',
    borderRadius: '8px',
    padding: '10px',
    transition: 'all 0.3s ease, transform 0.3s cubic-bezier(0.23, 1, 0.32, 1)',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
    fontSize: '18px',
  }, },
  { id: '4-2', data: { label: 'Embedded Systems Development' }, position: { x: -300, y: 330 }, className: 'child-node',
  style: {
    border: '2px',
    backgroundColor: '#000518',
    color: 'white',
    borderRadius: '8px',
    padding: '10px',
    transition: 'all 0.3s ease, transform 0.3s cubic-bezier(0.23, 1, 0.32, 1)',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
    fontSize: '18px',
  }, },
  { id: '4-3', data: { label: 'Cloud Integration & Data Processing' }, position: { x: 100, y: 300 }, className: 'child-node',
  style: {
    border: '2px',
    backgroundColor: '#000518',
    color: 'white',
    borderRadius: '8px',
    padding: '10px',
    transition: 'all 0.3s ease, transform 0.3s cubic-bezier(0.23, 1, 0.32, 1)',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
    fontSize: '18px',
  }, },
  { id: '4-4', data: { label: 'Device Prototyping and Testing' }, position: { x: 300, y: 330 }, className: 'child-node',
  style: {
    border: '2px',
    backgroundColor: '#000518',
    color: 'white',
    borderRadius: '8px',
    padding: '10px',
    transition: 'all 0.3s ease, transform 0.3s cubic-bezier(0.23, 1, 0.32, 1)',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
    fontSize: '18px',
  }, },

  { id: '5-1', data: { label: 'Social Media Strategy & Planning' }, position: { x: -350, y: -300 }, className: 'child-node',
  style: {
    border: '2px',
    backgroundColor: '#000518',
    color: 'white',
    borderRadius: '8px',
    padding: '10px',
    transition: 'all 0.3s ease, transform 0.3s cubic-bezier(0.23, 1, 0.32, 1)',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
    fontSize: '18px',
  }, },
  { id: '5-2', data: { label: 'Content Creation & Scheduling' }, position: { x: -550, y: -250 }, className: 'child-node',
  style: {
    border: '2px',
    backgroundColor: '#000518',
    color: 'white',
    borderRadius: '8px',
    padding: '10px',
    transition: 'all 0.3s ease, transform 0.3s cubic-bezier(0.23, 1, 0.32, 1)',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
    fontSize: '18px',
  }, },
  { id: '5-3', data: { label: 'Analytics & Performance Reporting' }, position: { x: -350, y: -100 }, className: 'child-node',
  style: {
    border: '2px',
    backgroundColor: '#000518',
    color: 'white',
    borderRadius: '8px',
    padding: '10px',
    transition: 'all 0.3s ease, transform 0.3s cubic-bezier(0.23, 1, 0.32, 1)',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
    fontSize: '18px',
  }, },
  { id: '5-4', data: { label: 'Community Management' }, position: { x: -550, y: -130 }, className: 'child-node',
  style: {
    border: '2px',
    backgroundColor: '#000518',
    color: 'white',
    borderRadius: '8px',
    padding: '10px',
    transition: 'all 0.3s ease, transform 0.3s cubic-bezier(0.23, 1, 0.32, 1)',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
    fontSize: '18px',
  }, },

  { id: '6-1', data: { label: 'Legal Advisory for Startups & SMEs' }, position: { x: -450, y: 25 }, className: 'child-node',
  style: {
    border: '2px',
    backgroundColor: '#000518',
    color: 'white',
    borderRadius: '8px',
    padding: '10px',
    transition: 'all 0.3s ease, transform 0.3s cubic-bezier(0.23, 1, 0.32, 1)',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
    fontSize: '18px',
  }, },
  { id: '6-2', data: { label: 'Financial Planning & Budgeting' }, position: { x: -650, y: -50 }, className: 'child-node',
  style: {
    border: '2px',
    backgroundColor: '#000518',
    color: 'white',
    borderRadius: '8px',
    padding: '10px',
    transition: 'all 0.3s ease, transform 0.3s cubic-bezier(0.23, 1, 0.32, 1)',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
    fontSize: '18px',
  }, },
  { id: '6-3', data: { label: 'Contract Drafting & Review' }, position: { x: -450, y: 175 }, className: 'child-node',
  style: {
    border: '2px',
    backgroundColor: '#000518',
    color: 'white',
    borderRadius: '8px',
    padding: '10px',
    transition: 'all 0.3s ease, transform 0.3s cubic-bezier(0.23, 1, 0.32, 1)',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
    fontSize: '18px',
  }, },
  { id: '6-4', data: { label: 'Taxation & Regulatory Compliance' }, position: { x: -650, y: 250 }, className: 'child-node',
style: {
    border: '2px',
    backgroundColor: '#000518',
    color: 'white',
    borderRadius: '8px',
    padding: '10px',
    transition: 'all 0.3s ease, transform 0.3s cubic-bezier(0.23, 1, 0.32, 1)',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
    fontSize: '18px',
  }, },

];



function Flow() {
  const { fitView, isNodeExist } = useReactFlow(); // Use React Flow context
  const [mounted, setMounted] = useState(false); // Track if the component has mounted

  useEffect(() => {
    setMounted(true); // Set the component as mounted after the first render
  }, []);

  useEffect(() => {
    if (mounted) {
      // Ensure fitView is only called after the component has mounted
      fitView();
    }
  }, [mounted, fitView]);

  return (
    <div style={{ height: '100vh', position: 'relative' }}>
      <ReactFlow nodes={nodes} edges={edges}>
        <Background />
        <Controls />
      </ReactFlow>
    </div>
  );
}


export default function ServicesPage() {
  return (
    <ReactFlowProvider>
      <Flow />
    </ReactFlowProvider>
  );
}