import React, { useState } from "react";
import axios from "axios";
import { FaLinkedinIn, FaTwitter, FaFacebook } from "react-icons/fa";

function ContactUs() {
  const [isHovered, setIsHovered] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    company: "",
    message: "",
  });
  const [responseMessage, setResponseMessage] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("http://localhost:5000/api/contact", formData);
      setResponseMessage(response.data.message);
      setFormData({ name: "", email: "", company: "", message: "" });
    } catch (error) {
      setResponseMessage("Failed to send message. Try again.");
      console.error(error);
    }
  };

  return (
    <div id="contactUs" className="max-w-[80vw] mx-auto p-4">
      <h1 className="text-center mb-10 text-black text-3xl font-bold">
        Let’s Start Your Success Story Together
      </h1>

      <div className="flex flex-wrap items-start justify-center gap-[40px] lg:gap-[200px]">
        {/* Contact Form */}
        <form
          className="w-full lg:flex-[0_0_40%] bg-white bg-opacity-25 p-8 rounded-[38px] shadow-md backdrop-blur-md flex flex-col gap-5 mb-8 lg:mb-20"
          onSubmit={handleSubmit}
        >
          {/* First Row: Name and Company */}
          <div className="flex flex-wrap gap-4">
            <div className="flex-1">
              <input
                type="text"
                name="name"
                placeholder="Name"
                required
                className="w-full p-2 rounded-lg border-2 border-teal-800 leading-7"
                value={formData.name}
                onChange={handleChange}
              />
            </div>
            <div className="flex-1">
              <input
                type="text"
                name="company"
                placeholder="Company"
                className="w-full p-2 rounded-lg border-2 border-teal-800 leading-7"
                value={formData.company}
                onChange={handleChange}
              />
            </div>
          </div>

          {/* Email Field */}
          <div>
            <input
              type="email"
              name="email"
              placeholder="Email"
              required
              className="w-full p-2 rounded-lg border-2 border-teal-800 leading-7"
              value={formData.email}
              onChange={handleChange}
            />
          </div>

          {/* Message Field */}
          <div>
            <textarea
              name="message"
              placeholder="Message"
              rows="5"
              required
              className="w-full p-2 rounded-lg border-2 border-teal-800 leading-7"
              value={formData.message}
              onChange={handleChange}
            ></textarea>
          </div>

          {/* Button Centered */}
          <div className="flex justify-center">
            <button
              type="submit"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              className={`w-[50%] p-2 ${
                isHovered ? "bg-[#ff0e6c] text-white" : "bg-transparent text-[#ff0e6c]"
              } border-2 border-[#ff0e6c] rounded-lg text-lg font-bold cursor-pointer transition-all duration-300 ${
                isHovered ? "transform scale-105 -translate-y-0.5 shadow-lg" : "shadow-md"
              }`}
            >
              Send
            </button>
          </div>
        </form>

        {/* Contact Info */}
        <div className="w-full lg:flex-[1_1_20%] bg-none p-6 rounded-[38px] mt-4 text-left">
          <h2 className="text-[#ff0e6c] text-xl font-bold mb-5">Let’s Connect</h2>
          <div className="text-black text-lg font-normal mb-5">
            <p>
              <strong>Email:</strong> solutions@gmail.com
            </p>
            <p>
              <strong>Phone:</strong> +91 XXXXX XXXXX
            </p>
            <p>
              <strong>Address:</strong> Insert Address Here
            </p>
          </div>
          <div className="flex gap-4">
            <a
              href="https://www.linkedin.com/in/yourprofile"
              target="_blank"
              rel="noopener noreferrer"
              className="w-9 h-9 flex items-center justify-center rounded-full bg-blue-600 text-white"
            >
              <FaLinkedinIn size={20} />
            </a>
            <a
              href="https://twitter.com/yourprofile"
              target="_blank"
              rel="noopener noreferrer"
              className="w-9 h-9 flex items-center justify-center rounded-full bg-blue-500 text-white"
            >
              <FaTwitter size={20} />
            </a>
            <a
              href="https://facebook.com/yourprofile"
              target="_blank"
              rel="noopener noreferrer"
              className="w-9 h-9 flex items-center justify-center rounded-full bg-blue-800 text-white"
            >
              <FaFacebook size={20} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;

