import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import FAQs from './components/FAQ';
import Onboarding from './components/Onboarding';
// import Industries from './components/Industries';
import TechSlider from './components/Tech';
import ContactUs from './components/ContactUs';
// import SubscriptionBar from './components/SubscriptionBar';
import Testimonials from './components/Testimonials';
import CookieConsentBanner from './components/CookieConsent';
import ServicesPage from './components/ServicesPage';
import AboutUs from './components/AboutUs';





function App() {
  return (
    <div className=" ">
      <Navbar/>
      {/* About us */}
      <AboutUs/>
      {/* Our services */}
      <ServicesPage/>
      <TechSlider></TechSlider>
      <Onboarding></Onboarding>
      <FAQs></FAQs>
      <ContactUs></ContactUs>
      <Testimonials></Testimonials>
      <Footer/>
      <CookieConsentBanner/>

    </div>
  );
}

export default App;
