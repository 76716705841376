import React, { useState } from "react";
import { fadeIn } from "../Variants";
import { motion } from "framer-motion";
const FAQs = () => {
    const[isOpen, setIsOpen]= useState(false);
    const toggleAnswer = ()=> {
        setIsOpen(!isOpen)
    }
    const faqData = [
        {
            id: 1,
            question : "What services does Sparkstart Solutions offer?",
            answer : " We offer a wide range of services, including: Web and mobile app development , Social media management, Graphic design and branding, IoT development and Legal, financial, and team management solutions.",

        },
        {
            id: 2,
            question : "Why should we choose Sparkstart Solutions?",
            answer : "Affordable and transparent pricing End-to-end business solutions under one roof, Timely delivery with 24/7 support , Experienced team of developers, designers, and strategists."
        },
        {
            id: 3,
            question : "How do I get started with Sparkstart Solutions?",
            answer : "Simply reach out to us via:📧 Email: support@sparkstartsolutions.tech🌐 Website: sparkstartsolutions.tech Our team will guide you through the process",
        },
        {
            id: 4,
            question : "Do you work with international clients?",
            answer : " Yes, we work with clients globally. Our team is experienced in handling international projects, ensuring seamless collaboration across time zones.",

        },
        {
            id:5,
            question: " What is the payment process?",
            answer: "We follow a milestone-based payment model to ensure transparency. Payment terms will be discussed before the project begins."
        },
        {
            id:6,
            question:"How do you ensure the quality of deliverables?",
            answer:"We follow a robust quality assurance process, including multiple reviews and client feedback, to ensure every deliverable meets high standards."
        },
    ]
    return(
        <section className=" text-gray-600 sm:flex-wrap  md:flex  flex-row justify-evenly items-center md:p-10 sm:p-0">
           {/* img */}
            <div className=" flex flex-col  ">
                <h1 className=" md:text-5xl sm:text-2xl text-[#ff0e6c] font-bold">
                     GOT QUESTIONS?
                </h1>
                <p className=" md:text-xl sm:text-lg font-semibold mt-6">Find Answers to Questions we always recieve. <br></br>If your Query is not here. Send us a Message.</p>
                <p className=" md:text-xl sm:text-lg font-semibold mt-6">We're here to Help</p>
                
            </div>
            <div className=" flex flex-col w-1/2">
                <h1 className=" font-bold sm:text-lg md:text-3xl text-start mb-5">Frequently Asked Questions (FAQs)</h1>
                {faqData.map((item,index) => (
                    <div
                    key={item.id}>
                        <div className=" sm:px-0 sm:text-center md:text-start sm:py-0 md:px-8 md:py-3">
                        <motion.div
                           variants={fadeIn("up", 0.1)}
                           initial="hidden"
                           whileInView={"show"}
                            viewport={{ once: false, amount: 0.7 }}
                            >
                            
                            <div className=" flex justify-between items-start">
                            <h2 className="sm:text-lg font-semibold  md:text-xl">{item.question}</h2>
                            <button onClick={toggleAnswer}>
                            <svg
                            className="ml-2 h-5 w-5"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                            >
                           <path
                           fillRule="evenodd"
                           d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          />
                          </svg>
                          </button>
                            </div>
                            {isOpen && (
                                <p className=" md:text-lg sm:text-base ">{item.answer}</p>
                            )}
                            
                            <div className=" flex flex-shrink border-t-2 mt-4"></div>
                            </motion.div>
                         </div>
                        

                    </div>

                ))}
            </div>

        </section>
    )

}
export default FAQs
