import React, { useEffect, useState } from "react";
import CookieConsent from "react-cookie-consent";

const CookieConsentBanner = () => {
  const [showBanner, setShowBanner] = useState(false);
  const [isHoveredAccept, setIsHoveredAccept] = useState(false);
  const [isHoveredReject, setIsHoveredReject] = useState(false);

  useEffect(() => {
    // Show the banner after 30 seconds
    const timer = setTimeout(() => {
      setShowBanner(true);
    }, 30000); // Adjusted for testing, change to 30000 for 30 seconds

    // Clean up the timer on component unmount
    return () => clearTimeout(timer);
  }, []);

  const handleButtonClick = () => {
    setShowBanner(false);
  };

  if (!showBanner) return null;  // If showBanner is false, return nothing

  return (
    <CookieConsent
      location="none" // Disable default location
      cookieName="userCookieConsent"
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 9999,
        background: "white", // Set background to white
      }}
      disableStyles // Disable default styles from CookieConsent
      buttonText="" // Disable default Accept button
      declineButtonText="" // Disable default Decline button
      enableDeclineButton={false} // Disable the default Decline button
      containerClasses="max-w-[95%] w-[600px] h-auto gap-4 rounded-lg overflow-hidden shadow-xl animate-slideUp flex flex-col"
    >
      <div className="w-full h-full flex flex-col">
        {/* Top Pink Section */}
        <div className="h-[150px] bg-pink-500 flex justify-center items-center rounded-t-lg">
          <h2 className="text-white text-[32px] sm:text-xl text-center">
            🍪 Cookie Preferences
          </h2>
        </div>

        {/* White Section */}
        <div className="bg-white text-[#000000] p-4 sm:p-6 flex flex-col justify-between items-center">
          {/* Paragraph */}
          <p className="text-center text-[16px] sm:text-[18px] leading-relaxed mb-4">
            We use cookies to personalize our website and offerings to your
            interests and for measurement and analytics purposes. By using our
            website and our products, you agree to our use of cookies.
          </p>

          {/* Buttons */}
          <div className="flex flex-col sm:flex-row justify-center gap-4 mb-4 w-full">
            <button
            onClick={handleButtonClick}
              onMouseEnter={() => setIsHoveredAccept(true)}
              onMouseLeave={() => setIsHoveredAccept(false)}
              className={`w-full sm:w-auto py-2 px-6 text-base font-bold ${
                isHoveredAccept
                  ? "bg-pink-700 text-white border-pink-700"
                  : "bg-white text-pink-600 border-pink-600"
              } border-2 rounded-lg hover:bg-pink-700 transition-all duration-300 shadow-md transform hover:scale-105`}
            >
              Accept All
            </button>
            <button
            onClick={handleButtonClick}
              onMouseEnter={() => setIsHoveredReject(true)}
              onMouseLeave={() => setIsHoveredReject(false)}
              className={`w-full sm:w-auto py-2 px-6 text-base font-bold ${
                isHoveredReject
                  ? "bg-pink-700 text-white border-pink-700"
                  : "bg-white text-pink-600 border-pink-600"
              } border-2 rounded-lg hover:bg-pink-700 transition-all duration-300 shadow-md transform hover:scale-105`}
            >
              Reject All
            </button>
          </div>

          {/* Read More Link */}
          <div className="flex justify-center text-base font-bold py-2">
            <a href="#" className="text-[#000518] underline">
              Read More
            </a>
          </div>
        </div>
      </div>
    </CookieConsent>
  );
};

export default CookieConsentBanner;











