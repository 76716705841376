// src/components/AboutUs.js
import React from 'react';
import AboutUsImage from '../assets/AboutUsImage.png'; // Adjust the path as necessary

const AboutUs = () => {
  return (
    <section className="about-us">
      <div className="about-us-image">
        <img
          src={AboutUsImage} // Image from assets folder
          alt="About Us"
          style={{ width: '100%', height: 'auto', borderRadius: '8px' }} // Customize the styles as needed
        />
      </div>
    </section>
  );
};

export default AboutUs;
