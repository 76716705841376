import { useEffect, useState } from "react";
import { motion } from "framer-motion";

export const fadeIn = (direction, delay) => {
    return {
      hidden: {
        y: direction === 'up' ? 40 : direction === 'down' ? -40 : 0,
        opacity: 0,
        x: direction === 'left' ? 40 : direction === 'right' ? -40 : 0,
      },
      show: {
        y: 0,
        x: 0,
        opacity: 1,
        transition: {
          type: 'tween',
          duration: 1.2,
          delay: delay,
          ease: [0.25, 0.25, 0.25, 0.75],
        },
      },
    };
};
export const Typewriter = ({text, speed=100}) => {
  const [displayedText, setDisplayedText] = useState("");

  useEffect(() => {
    let currentIndex = 0;
    const interval = setInterval(() => {
      if(currentIndex<text.length){
        setDisplayedText((prev) => prev + text[currentIndex]);
        currentIndex++;
      }
      else{
        clearInterval(interval);
      }
    }, speed)
    return() => clearInterval(interval);
  }, [text, speed]);
  return(
    <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 0.5 }}>
      {displayedText}
      <motion.span
        className="inline-block bg-gray-800 w-1 h-6"
        animate={{ opacity: [1, 0] }}
        transition={{
          repeat: Infinity,
          duration: 0.8,
          ease: "easeInOut",
        }}
      />
    </motion.div>
  )

}