
import React, { useState , useEffect, useRef} from "react";
import logo from '../assets/SparkStartLogo.png'
import { CiMenuFries } from "react-icons/ci";
import Dropdown from "./Dropdown";
import { fadeIn } from "../Variants";
import { motion } from "framer-motion";

const Header = () => {
  const [activeLink, setActiveLink] = useState("HOME");
  const [isOpen, setIsOpen] = useState(false);
  const toggleSection = () => {
    setIsOpen(!isOpen);
  }

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };
  // const [isFixed, setIsFixed] = useState(false);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const videoHeight = window.innerHeight; // h-screen height
  //     if (window.scrollY > videoHeight) {
  //       setIsFixed(true);
  //     } else {
  //       setIsFixed(false);
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, []);

  return (
  <div>
    <div className="relative w-full h-screen overflow-hidden">
      <video
        className=" absolute top-0 left-0  w-full h-full object-cover"
        autoPlay
        muted
        loop
        playsInline
      >
        <source src="./assets/HerosectionVideo.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {/* <div className=" absolute">
        <button>
          <CiMenuFries onClick={toggleSection}
          />
          {isOpen ? "close" : "open"}
        </button>

      </div>
      <div className={` absolute top-0 right-0 h-full w-full sm:w-2/3 bg-white transform transition-transform duration-500 {isOpen ? " translate-x-0" : "translate-x-full"}`}>

      </div>
      */}
    </div>
    {/* <motion.div
     variants={fadeIn("up", 0.1)}
     initial="hidden"
     whileInView={"show"}
     viewport={{ once: false, amount: 0.7 }}
     className= {`${isFixed ? "fixed top-0 w-full" : ""} `}
     > */}
    {/* <div  className= {`${isFixed ? "fixed top-0 w-full" : ""} `}>
     <header className="text-gray-600 relative bg-sky-50 body-font h-24">
      <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
        <a className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0">
         <img src={logo} className="   h-16 scale-125 w-16 "></img>
          <span className="ml-3 text-xl">SPARKSTART SOLUTIONS</span>
        </a>
        <nav className="md:ml-auto md:text-lg sm:text-base flex flex-wrap items-center  justify-center">
          {["HOME", "ABOUT US", "SERVICES", "OUR CLIENTS", "PRICING"].map(
            (link) => (
              <a
                key={link}
                onClick={() => handleLinkClick(link)}
                className={`mr-5 cursor-pointer  ${
                  activeLink === link ? "underline text-[#d06055] font-bold" : ""
                }`}
              >
                {link}
              </a>
            )
          )}
          <div className=" flex gap-3">
           <Dropdown></Dropdown>
          </div>
        </nav>
        
        <button className="inline-flex items-center bg-[#d06055] ml-3  text-white rounded-lg border-0 py-1 px-3 focus:outline-none hover:bg-gray-200   mt-4 md:mt-0">
          CONTACT US
          <svg
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            className="w-4 h-4 ml-1"
            viewBox="0 0 24 24"
          >
            <path d="M5 12h14M12 5l7 7-7 7" />
          </svg>
        </button>
      </div>
     </header>
     </div> */}
    {/* </motion.div> */}
    </div>
  );
};

export default Header;

