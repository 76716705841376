import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import testimonial from '../assets/testimonial.png';
import testimonial3 from '../assets/testimonial3.png';
import client01 from '../assets/client01.png';
import client02 from '../assets/client02.png';
import client03 from '../assets/client03.png';
import client04 from '../assets/client04.png';

const clients = [
  { id: 1, name: "Client 1", image: client01 },
  // { id: 2, name: "Client 2", image: client02 },
  { id: 2, name: "Client 3", image: client03 },
  { id: 3, name: "Client 4", image: client04 },
];

const testimonials = [
  {
    id: 1,
    name: "Helly Quin",
    image: testimonial,
    rating: 5,
    description: "Spark Start Solutions, we specialize in bringing ideas to life for startups, small businesses, and enterprises around the globe. Whether you're launching a new venture or scaling an established brand, we provide personalized strategies to help you succeed in a competitive market.", 
  },
  {
    id: 2,
    name: "Jane Smith",
    image: testimonial,
    rating: 4,
    description: "Spark Start Solutions, we specialize in bringing ideas to life for startups, small businesses, and enterprises around the globe. Whether you're launching a new venture or scaling an established brand, we provide personalized strategies to help you succeed in a competitive market.", 
  },
  {
    id: 3,
    name: "Alice Johnson",
    image: testimonial3,
    rating: 5,
    description: "Spark Start Solutions, we specialize in bringing ideas to life for startups, small businesses, and enterprises around the globe. Whether you're launching a new venture or scaling an established brand, we provide personalized strategies to help you succeed in a competitive market.", 
  },
];

const Testimonials = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  return (
    <div className="min-h-screen relative">
      {/* "Our Clients" Heading */}
      <div className="text-center py-4">
        <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-[#ff0e6c] uppercase">Our Clients</h2>
      </div>

      {/* Moving Chain */}
      <div className="relative flex justify-center items-center overflow-hidden mt-4">
        <div className="flex items-center justify-between whitespace-nowrap animate-scrollChain">
          {[...clients].map((client, index) => (
            <div key={index} className="inline-block text-center mx-4 md:mx-10">
              <img
                src={client.image}
                alt={client.name}
                className="w-32 h-20 sm:w-36 sm:h-28 md:w-48 md:h-36 rounded-xl border-4"
              />
            </div>
          ))}
        </div>
      </div>

      {/* Add keyframes for seamless scrolling */}
      <style>
        {`
          @keyframes scrollChain {
            0% { transform: translateX(-100%); }
            100% { transform: translateX(100%); }
          }

          .animate-scrollChain {
            animation: scrollChain 10s linear infinite;
          }
        `}
      </style>

      {/* Header */}
      <div className="relative top-1/4 left-0 w-full px-4 sm:px-6 md:px-10 flex justify-between items-center">
        <div>
          <h1 className="text-xl sm:text-2xl md:text-3xl font-bold text-[#ff0e6c] uppercase py-4">Testimonials</h1>
          <p className="text-lg sm:text-xl md:text-2xl font-bold text-black">What people say about us</p>
        </div>
        <div className="text-xl sm:text-2xl md:text-3xl font-bold text-black pr-4 md:pr-24">
          &lt;{currentSlide + 1}/{testimonials.length}&gt;
        </div>
      </div>

      {/* Spacing for fixed header */}
      <div className="w-full flex justify-center py-8">
        {/* Swiper Slider */}
        <div className="w-full max-w-6xl pt-6">
          <Swiper
            modules={[Autoplay]}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            spaceBetween={30}
            slidesPerView={1}
            onSlideChange={(swiper) => setCurrentSlide(swiper.realIndex)}
          >
            {testimonials.map((testimonial) => (
              <SwiperSlide key={testimonial.id}>
                <div className="flex flex-col md:flex-row items-center bg-white rounded-xl shadow-lg p-5 w-full">
                  {/* Left Side: Profile */}
                  <div className="flex flex-col items-center justify-center p-5 w-full md:w-1/3">
                    <img
                      src={testimonial.image}
                      alt={testimonial.name}
                      className="w-32 h-32 sm:w-36 sm:h-36 md:w-40 md:h-40 rounded-full border-4 border-[#ff0e6c] mb-4"
                    />
                    <h3 className="text-lg sm:text-xl md:text-2xl font-bold text-[#ff0e6c] text-center">{testimonial.name}</h3>
                  </div>

                  {/* Right Side: Content */}
                  <div className="flex-1 p-5 w-full md:w-2/3">
                    <div className="text-xl sm:text-2xl text-yellow-500 text-left mb-4">
                      {"★".repeat(Math.floor(testimonial.rating))}
                    </div>
                    <p className="text-lg sm:text-xl md:text-[18px] text-black text-left leading-relaxed pr-0 md:pr-14">
                      {testimonial.description}
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;


