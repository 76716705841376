import React from "react";
import { fadeIn } from "../Variants";
import { motion } from "framer-motion";

const Onboarding = () => {
  const onboardingSteps = [
    {
      id: 1,
      title: "Identifying Vacancies",
      description: "Develop clear and concise job descriptions that detail the responsibilities, qualifications, and skills required for te position.",
      iconPath: "M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z",
    },
    {
      id: 2,
      title: "Creating Job Descriptions",
      description: "The process begins by identifying the need for new hires, including the skills and qualifications necessary for the role.",
      iconPath: "M22 12h-4l-3 9L9 3l-3 9H2",
    },
    {
      id: 3,
      title: "Publishing Job Ads",
      description: "Reviewing applications and resumes to shortlist candidates who best match the job requirements",
      iconPath: "M12 22V8M5 12H2a10 10 0 0020 0h-3",
      secondaryIcon: "M12 5a3 3 0 100-6 3 3 0 000 6z",
    },
    {
      id: 4,
      title: "Conducting Interviews",
      description: "Extending job offers and integrating new hires through onboarding, including policy orientation",
      iconPath: "M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2",
      secondaryIcon: "M12 7a4 4 0 100-8 4 4 0 000 8z",
    },
    {
        id: 5,
        title: "Screening Applications",
        description: "Advertising vacancies on various platform like the company”s career page, job boards, and social media.",
        iconPath: "M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2",
        secondaryIcon: "M12 7a4 4 0 100-8 4 4 0 000 8z",
    },
    {
        id: 6,
        title: "Job Offers & Onboarding",
        description: "Interviewing shortlisted candidates to assess qualification, experience, and cultural fit, possibly via phone or in person",
        iconPath: "M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2",
        secondaryIcon: "M12 7a4 4 0 100-8 4 4 0 000 8z",
    }
  ];
  return (
    <section className="text-gray-600 body-font">
      <h1 className=" text-4xl mt-24 text-center text-gray-700 font-semibold">Onboarding Clients</h1>
      <div className="container px-5 py-24 mx-auto flex flex-wrap">
        {onboardingSteps.map((item, index) => (
          <div
            key={item.id}
            className={`flex relative ${
              index === onboardingSteps.length - 1 ? "pb-10" : "pb-20"
            } sm:items-center md:w-2/3 mx-auto`}
          >
           

             <div className="h-full w-6 absolute inset-0 flex items-center justify-center">
             
               <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
               
             </div>
             
             <div className="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-[#ff0e6c] text-white z-10 title-font font-medium text-sm">
               {item.id}
             </div>
            <motion.div
            variants={fadeIn("up", 0.1)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.7 }}
            >
            <div className="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">
              <div className="flex-shrink-0 w-24 h-24 bg-indigo-200 text-indigo-500 rounded-full inline-flex items-center justify-center">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  className="w-12 h-12"
                  viewBox="0 0 24 24"
                >
                  {item.secondaryIcon && (
                    <circle
                      cx="12"
                      cy="5"
                      r="3"
                    />
                  )}
                  <path d={item.iconPath}></path>
                </svg>
              </div>
              <div className="flex-grow sm:pl-6 mt-6 sm:mt-0">
                <h2 className="font-medium title-font text-gray-900 mb-1 text-xl">
                  {item.title}
                </h2>
                <p className="leading-relaxed">{item.description}</p>
              </div>
            </div>
            </motion.div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Onboarding;
